<template>
  <div>
    <div class="item" v-for="(item, index) in dataList" :key="item.title">
      <div class="index">{{index + 1}}</div>
      <div>
        <div :style="{textIndent: /^【/.test(item.title) ? '-6px' : 0}">{{item.title}}</div>
        <div>{{item.time}}</div>
        <div class="link">
          链接:
          <a :href="item.link" style="display: inline-block">报名链接</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "MTmpTutorClassTemplate",
    props: {
      dataList: {
        type: Array,
        required: true
      },
    },
    data() {
      return {
        // dataList: [
        //   {
        //     title: '【1128】第四期少年财商素质教育导师培训班（上午）',
        //     time: '时间：11月28日 07：30-12：20',
        //     link: 'https://event.3188.la/1957694565'
        //   },
        //   {
        //     title: '【1128】第四期少年财商素质教育导师培训班（下午）',
        //     time: '时间：11月28日 13：30-18：25',
        //     link: 'https://event.3188.la/1957709851'
        //   },
        //   {
        //     title: '【1128】第四期少年财商素质教育导师培训班（晚上）',
        //     time: '时间：11月28日 19：00-20：30',
        //     link: 'https://event.3188.la/1957748557'
        //   },
        //   {
        //     title: '【1129】第四期少年财商素质教育导师培训班（上午）',
        //     time: '时间：11月29日 08：30-12：20',
        //     link: 'https://event.3188.la/1957748668'
        //   },
        // ]
      }
    }
  }
</script>

<style lang="scss" scoped>
a {
  color: #409eff!important;
  text-decoration: underline;
}
.item {
  padding: 16px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.link {
  width: 100%;
  overflow: hidden;
}
.index {
  height: 30px;
  width: 30px;
  background: #409eff;
  border-radius: 15px;
  margin-right: 10px;

  color: #ffffff;
  font-weight: bold;
  text-align: center;
  line-height: 30px;

  flex-shrink: 0;
}
</style>
