<template>
  <MTmpTutorClassTemplate title="2021年财商少年冬令营" :data-list="dataList"></MTmpTutorClassTemplate>
</template>

<script>
import MTmpTutorClassTemplate from "@/m-views/m-tmp/tutor-class/MTmpTutorClassTemplate";
export default {
  name: "MTmpTutorClass2",
  components: {
    MTmpTutorClassTemplate
  },
  metaInfo: {
    title: '财商少年导师班',
  },
  data() {
    return {
      dataList: [
        {
          title: '第五期少年财商素质教育导师培训班（上午）',
          time: '时间：2020年12月19日 8:30~13:00',
          link: 'https://event.3188.la/1979719271'
        },
        {
          title: '第五期少年财商素质教育导师培训班（下午）',
          time: '时间：2020年12月19日 14:00~18:00',
          link: 'https://event.3188.la/1979741421'
        },
        {
          title: '第五期少年财商素质教育导师培训班（晚上）',
          time: '时间：2020年12月19日 20:00',
          link: 'https://event.3188.la/1979759136'
        },
        {
          title: '第五期少年财商素质教育导师培训班（上午）',
          time: '时间：2020年12月20日 12:30',
          link: 'https://event.3188.la/1979758487'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
